export type Formdata = Record<
  string,
  string | number | boolean | object | any[] | undefined
>

export const setKey = Symbol('set') as InjectionKey<
  (field: string, value: any) => void
>
export const resetKey = Symbol('reset') as InjectionKey<() => void>
export const submitKey = Symbol('submit') as InjectionKey<
  () => Promise<boolean>
>
export const formdataKey = Symbol('formdata') as InjectionKey<Formdata>
export const fieldErrorsKey = Symbol('fieldErrors') as InjectionKey<
  Record<string, string[]>
>
export const setFieldErrorsKey = Symbol('setFieldErrors') as InjectionKey<
  (field: string, errors: string[]) => void
>
export const registerKey = Symbol('register') as InjectionKey<
  (name: string, formField: any) => void
>
